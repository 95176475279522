/**
 * Application roles list
 */
export enum RoleNames {
  Administrator = 'Admin',
  ApplicationAccess = 'ApplicationAccess',
  TechnicalManager = 'technicalmanager',
  CommunityManager = 'communitymanager',
  CommunityPromotor = 'communitypromotor',
  Client = 'client',
}
