import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PanelMenuModule } from 'primeng/panelmenu';
import {
  PrgSharedComponentsModule,
  PrgCoreDynamicFormModule,
  PrgSidebarMenuModule,
  PrgLayoutsModule,
  PrgAuthModule,
  SecurePipe,
} from 'prg-core-lib';
import { QualipexHeaderComponent } from './components/header/qualipex-header.component';
import { QualipexLayoutsHeaderSidebarComponent } from './pages/qualipex-layouts-header-sidebar';
import { QualipexTitleComponent } from './components/title/qualipex-title.component';
import { DialogModule } from 'primeng/dialog';
import { UsersPageModule } from '../users/users.page.module';
import { ButtonModule } from 'primeng/button';

/**
 * Module that handles all page layouts
 */
@NgModule({
  declarations: [
    QualipexHeaderComponent,
    QualipexLayoutsHeaderSidebarComponent,
    QualipexTitleComponent,
  ],
  imports: [
    PrgSharedComponentsModule,
    SecurePipe,
    IonicModule,
    PanelMenuModule,
    PrgCoreDynamicFormModule,
    PrgSidebarMenuModule,
    PrgLayoutsModule,
    PrgAuthModule,
    DialogModule,
    UsersPageModule,
    ButtonModule,
  ],
  exports: [
    QualipexHeaderComponent,
    QualipexLayoutsHeaderSidebarComponent,
    QualipexTitleComponent,
  ],
})
export class QualipexLayoutsModule {}
