import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TranslateModule } from '@ngx-translate/core';
import {
  PrgCoreDynamicFormModule,
  PrgUserManagementModule,
} from 'prg-core-lib';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { UserProfileFormPage } from './components/user-profile-form/user-profile-form.page';

import { UsersRoutingModule } from './users-routing.module';
import { UsersPage } from './users.page';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ToolbarModule,
    TableModule,
    ButtonModule,
    TagModule,
    ToastModule,
    TooltipModule,
    TranslateModule,
    ChipModule,
    DialogModule,
    UsersRoutingModule,
    PrgCoreDynamicFormModule,
    InputTextModule,
    RippleModule,
    RadioButtonModule,
    DropdownModule,
    MultiSelectModule,
    PrgUserManagementModule,
  ],
  declarations: [UsersPage, UserProfileFormPage],
  exports: [UserProfileFormPage],
})
export class UsersPageModule {}
