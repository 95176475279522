import { Injectable } from '@angular/core';

import {
  ApiGenericService,
  InterceptorConfig,
  UtilityService,
} from 'prg-core-lib';
import { WorkspaceUser } from '../models/workspace-user.model';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceUserService {
  private static ControllerUrl = 'UserWorkspace';
  private static UserProfileUrl = 'UserProfile';
  constructor(
    private apiGenericService: ApiGenericService,
    private utilityService: UtilityService
  ) {}

  public deleteAsync(
    userId: string,
    useCurrentWorkspace: boolean = false
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let response = await this.apiGenericService.delete(
          this.utilityService.normalizeUrl(
            WorkspaceUserService.UserProfileUrl,
            'DeleteFromWorkspace',
            userId
          ),
          null,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
            sendWorkspaceToken: useCurrentWorkspace,
          })
        );
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public inviteUserAsync(
    emails: string[],
    roleName: string,
    clientId: string = null,
    communityIds: string[] = null,
    useCurrentWorkspace: boolean = false
  ): Promise<WorkspaceUser[]> {
    return new Promise<WorkspaceUser[]>(async (resolve, reject) => {
      try {
        let response = await this.apiGenericService.post(
          'userProfile/inviteUsers',
          {
            emails: emails,
            roleName: roleName,
            clientId: clientId,
            communityIds: communityIds,
          },
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
            sendWorkspaceToken: useCurrentWorkspace,
          })
        );

        resolve(response.entity);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getWorkspaceUserListAsync(
    all: boolean,
    forWorkspace: boolean = false
  ): Promise<WorkspaceUser[]> {
    return new Promise<WorkspaceUser[]>(async (resolve, reject) => {
      try {
        const response = await this.apiGenericService.get(
          this.utilityService.normalizeUrl(
            WorkspaceUserService.ControllerUrl,
            'list'
          ),
          all.toString(),
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
            sendWorkspaceToken: forWorkspace,
          })
        );

        resolve(response.entity);
      } catch (error) {
        reject(error);
      }
    });
  }

  public stateChangeAsync(
    userId: string,
    userWorkspaceStateId: string,
    useCurrentWorkspace: boolean = false
  ): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const response = await this.apiGenericService.put(
          this.utilityService.normalizeUrl(
            WorkspaceUserService.UserProfileUrl,
            'ChangeState',
            userId,
            userWorkspaceStateId
          ),
          null,
          null,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
            sendWorkspaceToken: useCurrentWorkspace,
          })
        );

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  public getWorkspaceRoles(): Promise<any[]> {
    return new Promise<any[]>(async (resolve, reject) => {
      try {
        const response = await this.apiGenericService.get(
          'Role/MinimalListRoles',
          null,
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
            sendAuthToken: true,
          })
        );
        resolve(response.entity);
      } catch (error) {
        reject(error);
      }
    });
  }
}
