import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractAuthService,
  BaseActionKey,
  BaseField,
  DynamicFormActionOutput,
  EntityType,
  ObjectsUtilityService,
  PrgDynamicFormComponent,
  User,
  UtilityService,
  ViewMode,
} from 'prg-core-lib';
import { UserProfileEntitiesOperationNames } from '../../models/user-profile-entities-operation-names-enum';
import { UserProfile } from '../../models/user-profile.model';
import { UserProfileService } from '../../services/user-profile-service/user-profile.service';

import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-profile-form.page.html',
  styleUrls: ['./user-profile-form.page.scss'],
})
/**
 * User Profile Form page
 */
export class UserProfileFormPage implements OnInit, OnDestroy {
  /**
   * The view mode type of the form. Default is "read"
   * @type {ViewMode}
   */
  public userProfileViewMode: ViewMode = ViewMode.Edit;

  /**
   * The dynamic form fields to build dynamic form user profile in template
   */
  public userProfileDynamicFormFields: BaseField[];

  /**
   * User Data
   * @type {UserProfile}
   */
  public userData: UserProfile = new UserProfile({});

  /**
   * User Id
   * @type {string}
   */
  @Input() userId: string;

  /**
   * User Id
   * @type {string}
   */
  @Output() userProfileUpdate = new EventEmitter<UserProfile>();

  /**
   * Entity data from user profile
   * @type {EntityType}
   */
  public userProfileEntityType: EntityType;

  /**
   * Constructor
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {UtilityService} utilityService
   * @param {ObjectsUtilityService} objectUtility
   * @param {AbstractAuthService} authService
   * @param {UserProfileService} userProfileService
   * @param {ActivatedRoute} activatedRoute
   */
  constructor(
    private utilityService: UtilityService,
    private objectUtility: ObjectsUtilityService,
    private authService: AbstractAuthService,
    private userProfileService: UserProfileService
  ) {}

  /**
   * ngOnDestroy
   */
  public ngOnDestroy(): void {}
  /**
   * ngOnInit
   */
  public ngOnInit(): void {
    let userLogged: User = this.objectUtility.clone(
      this?.authService?.getLoggedUser()
    );
    if (this.userId == null) {
      this.userId = userLogged?.id;
    }
    this.getUserProfileEntity();
  }

  /**
   * Method to get user profile entity data
   * @returns {Promise<void>}
   */
  private async getUserProfileEntity() {
    try {
      const responseUserProfileEntity =
        await this.userProfileService.manageUserProfileDataByEntityServiceAsync(
          'userprofile',
          null,
          null,
          true
        );

      if (responseUserProfileEntity == null) {
        return;
      }

      this.userProfileEntityType = this.objectUtility.clone(
        responseUserProfileEntity
      );

      const userProfileDynamicFormFields =
        this.utilityService.getBaseFieldsFromEntityType(
          this.userProfileEntityType
        );

      if (this.userId) {
        await this.getUserData();
      }

      this.userProfileDynamicFormFields = userProfileDynamicFormFields;
    } catch (e) {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  /**
   * Method to get user data
   * @returns {Promise<void>}
   */
  private async getUserData() {
    try {
      const responseUser =
        await this.userProfileService.manageUserProfileDataByEntityServiceAsync(
          'userprofile',
          UserProfileEntitiesOperationNames.ById,
          {
            id: this.userId,
          },
          false
        );

      if (responseUser?.entity == null) {
        return;
      }
      this.userData = this.objectUtility.clone(responseUser?.entity);
    } catch (e) {
      /*await this.navigationService.backToPreviousRoute();*/
    }
  }

  public onFormValueChanged(form: FormGroup): void {
    if (form == null) return;
    this.userProfileUpdate.emit(form.value);
  }
}
