import { Injectable } from '@angular/core';
import {
  AbstractEntityTypeService,
  AbstractAuthService,
  ApiGenericService,
  UtilityService,
  InterceptorConfig,
} from 'prg-core-lib';
import { UserProfileEntitiesOperationNames } from '../../models/user-profile-entities-operation-names-enum';
import { WorkspaceUser } from '../../models/workspace-user.model';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private static UserProfileUrl = 'UserProfile';
  constructor(
    private apiGenericService: ApiGenericService,
    private authService: AbstractAuthService,
    private entityTypeService: AbstractEntityTypeService,
    private utilityService: UtilityService
  ) {}

  public async GetClientUsers(
    clientId: string,
    all: boolean = false
  ): Promise<WorkspaceUser[]> {
    return new Promise<WorkspaceUser[]>(async (resolve, reject) => {
      try {
        const response = await this.apiGenericService.get(
          this.utilityService.normalizeUrl(
            UserProfileService.UserProfileUrl,
            'GetClientUsers',
            clientId
          ),
          all.toString(),
          new InterceptorConfig({
            apiRequest: true,
            handleLoading: true,
            handleErrors: true,
            sendWorkspaceToken: true,
          })
        );

        resolve(response.entity);
      } catch (error) {
        reject(error);
      }
    });
  }

  /**
   * Manage User Profile Data through the entity service
   * @param {string} entityName
   * @param {boolean} onlyEntity
   * @param {UserProfileEntitiesOperationNames} operationName
   * @param entity
   * @returns {Promise<any>}
   */
  public async manageUserProfileDataByEntityServiceAsync(
    entityName: string,
    operationName?: UserProfileEntitiesOperationNames,
    entity?: any,
    onlyEntity: boolean = false
  ): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const entityType =
          await this.entityTypeService.getAllEntityTypeDataByName(entityName);
        if (entityType != null) {
          if (onlyEntity) {
            resolve(entityType);
            return;
          }
          const operationRequest = entityType.operations.find(
            (operation) => operation.name == operationName
          );

          if (operationRequest != null) {
            const entityRequest: any =
              await this.entityTypeService.executeAction(
                entityName,
                operationRequest,
                entity
              );

            if (
              entityRequest?.entity?.id === this.authService.getLoggedUser().id
            ) {
              this.authService.updateLoggedUser(
                entityRequest?.entity?.name,
                entityRequest?.entity?.applicationUser?.userName,
                entityRequest?.entity?.applicationUser?.imageUrl
              );
            }
            resolve(entityRequest);
          }
        }
      } catch (e) {
        await reject(e);
      }
    });
  }
}
