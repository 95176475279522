export enum WorkspaceUserStates {
  'Active' = 'userworkspacestates.active',
  'Inactive' = 'userworkspacestates.inactive',
}

export class WorkspaceUser {
  /**
   * Id
   */
  public id: string;

  /**
   * Name
   */
  public name: string;
  /**
   * Email
   */
  public email: string;

  public roles: string[];

  public userWorkspaceStateId: string;

  /**
   * Constructor
   * @param {Partial<WorkspaceUser>} init
   */
  public constructor(init?: Partial<WorkspaceUser>) {
    Object.assign(this, init);
  }
}
