import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Toast } from 'primeng/toast';
import { first, Subscription } from 'rxjs';
import {
  IsLoadingDataService,
  ObjectsUtilityService,
  UtilityService,
  PrgConfigLayouts,
  MainLayoutService,
  TokenService,
} from 'prg-core-lib';
import { QualipexHeaderService } from '../services/qualipex-header.service';
import { UserProfile } from 'src/app/users/models/user-profile.model';
import { UserProfileService } from 'src/app/users/services/user-profile-service/user-profile.service';
import { UserProfileEntitiesOperationNames } from 'src/app/users/models/user-profile-entities-operation-names-enum';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

/**
 * layout page
 */
@Component({
  selector: 'qualipex-layouts-header-sidebar',
  templateUrl: './qualipex-layouts-header-sidebar.html',
  styleUrls: ['./qualipex-layouts-header-sidebar.scss'],
})
/**
 * Page Main Layout
 */
export class QualipexLayoutsHeaderSidebarComponent
  implements OnInit, AfterContentChecked, AfterViewInit
{
  /**
   * displayUserProfile value
   */
  public displayUserProfile: boolean = false;

  /**
   * updatedUserProfile value
   */
  private updatedUserProfile: UserProfile;

  /**
   * spinnerVisible value
   */
  public spinnerVisible: boolean = false;

  /**
   * workspace name
   */
  public workspaceName: string = '';

  /**
   * Save button is disabled
   * @returns {boolean}
   */
  public userProfileSaveDisabled: boolean = false;

  /**
   * Default Workspace name
   * @type {string}
   * @private
   */
  private readonly DEFAULT_WORKSPACE: string = 'default';

  /**
   * subscriptions
   */
  private subscriptions: Subscription[] = [];

  /**
   * Config layout
   * @type {PrgConfigLayouts}
   * @private
   */
  public configLayouts: PrgConfigLayouts = new PrgConfigLayouts({});

  /**
   * spinner element
   * @type {any}
   * @private
   */
  private spinner: any;

  /**
   * overlay element
   * @type {any}
   * @private
   */
  private overlay: any;

  /**
   * toast element
   * @type {any}
   * @private
   */
  private toast: any;

  /**
   * toast element
   * @type {Toast}
   */
  @ViewChild('toast') toastElement: Toast;

  /**
   * constructor
   * @param {ChangeDetectorRef} cdr
   * @param {IsLoadingDataService} isLoadingData isLoadingData
   * @param {ObjectsUtilityService} objectsUtilityService
   * @param {UtilityService} utilityService
   * @param {MainLayoutService} mainLayoutService
   * @param {QualipexHeaderService} qualipexHeaderService
   * @param {UserProfileService} userProfileService
   * @param {ElementRef} elementRef
   */
  constructor(
    private cdr: ChangeDetectorRef,
    public isLoadingData: IsLoadingDataService,
    private objectsUtilityService: ObjectsUtilityService,
    private utilityService: UtilityService,
    private mainLayoutService: MainLayoutService,
    private qualipexHeaderService: QualipexHeaderService,
    private userProfileService: UserProfileService,
    private elementRef: ElementRef,
    private tokenService: TokenService,
    public route: ActivatedRoute
  ) {}

  /**
   * onInit
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.tokenService
        .getCurrentWorkspaceTokenObservable()
        .subscribe((value) => {
          this.workspaceName =
            value == null || value.workspace === this.DEFAULT_WORKSPACE
              ? this.DEFAULT_WORKSPACE
              : '';
        })
    );

    this.subscriptions.push(
      this.mainLayoutService.getLayoutsConfigObservable().subscribe((value) => {
        this.configLayouts = this.objectsUtilityService.clone(value);
      })
    );

    this.subscriptions.push(
      this.qualipexHeaderService
        .getDisplayUserProfileObservable()
        .subscribe((value) => {
          this.displayUserProfile = value;
        })
    );
  }

  /**
   * ngOnDestroy
   */
  ngOnDestroy(): void {
    if (this.spinner != null) {
      document?.body?.removeChild(this.spinner);
    }
    if (this.overlay != null) {
      document?.body?.removeChild(this.overlay);
    }
    if (this.toast != null) {
      document?.body?.removeChild(this.toast);
    }
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  /**
   * ngAfterContentChecked
   */
  ngAfterContentChecked(): void {
    this.isLoadingData
      .getIsLoadingObservable()
      .pipe(first())
      .subscribe((element) => {
        this.spinnerVisible = element.showSpinner;
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.spinner =
      this.elementRef?.nativeElement?.querySelector('#prg-spinner-app');
    this.overlay =
      this.elementRef?.nativeElement?.querySelector('#prg-overlay');
    this.toast =
      this.toastElement?.containerViewChild?.nativeElement?.parentElement;
    if (this.spinner != null) {
      document?.body?.appendChild(this.spinner);
    }
    if (this.overlay != null) {
      document?.body?.appendChild(this.overlay);
    }
    if (this.toast != null) {
      document?.body?.appendChild(this.toast);
    }
  }

  public onUserProfileUpdated(updatedUserProfile: UserProfile): void {
    this.updatedUserProfile = updatedUserProfile;
  }

  public async updateUserProfileAsync(): Promise<void> {
    this.userProfileSaveDisabled = true;
    await this.utilityService.sleepMsAsync(
      environment.coreModuleConfigurations.dynamicForm.formValueChangeDebounce +
        environment.coreModuleConfigurations.dynamicForm
          .inputValueChangeDebounce
    );

    const responseUser =
      await this.userProfileService.manageUserProfileDataByEntityServiceAsync(
        'userprofile',
        UserProfileEntitiesOperationNames.Update,
        this.updatedUserProfile,
        false
      );

    this.displayUserProfile = false;
    this.userProfileSaveDisabled = false;
  }

  public closeProfileDialog(): void {
    this.qualipexHeaderService.setDisplayUserProfileValue(false);
    this.updatedUserProfile = null;
  }
}
