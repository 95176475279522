import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { User } from 'prg-core-lib';
import { AbstractAuthService } from 'prg-core-lib';
import { ArrayUtilityService } from 'prg-core-lib';
import { ObjectsUtilityService } from 'prg-core-lib';
import { UtilityService } from 'prg-core-lib';
import { PrgMenuItem } from 'prg-core-lib';
import { MainLayoutService } from 'prg-core-lib';
import { QualipexHeaderService } from '../../services/qualipex-header.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

//TODO: It's missing a way to save user and language preferences (Session / Locale Storage)
/**
 * Header Component
 */
@Component({
  selector: 'qualipex-header',
  templateUrl: './qualipex-header.component.html',
  styleUrls: ['./qualipex-header.component.scss'],
})
export class QualipexHeaderComponent implements OnInit, OnDestroy {
  /**
   * Header Search Debounce time (ms)
   */
  private readonly SEARCH_DEBOUNCE_MS: number = 1000;
  public imagePathUserDefault: string = '/assets/images/svg/nav_topo/user.svg';
  /**
   * Property of the class with type string which bind to user image
   */
  public imagePathUser: string;
  /**
   * Current logged user
   * @type {User}
   */
  public user: User;
  /**
   * Menu Items data of user menu
   * @type {PrgMenuItem[]}
   */
  public menuItemsUser: PrgMenuItem[] = [];
  /**
   * Menu Items data of language menu
   * @type {PrgMenuItem[]}
   */
  public menuItemsLanguage: PrgMenuItem[] = [];
  /**
   * Current sidebar state
   * @type {boolean}
   */
  public isSideBarExpanded: boolean = false;
  /**
   * displaySearchInput
   * @type {boolean}
   */
  public displaySearchInput: boolean = false;
  /**
   * searchString
   * @type {string}
   */
  public searchString: string = null;
  /**
   * A class property used to unsubscribe observables on ngOnDestroy
   * @type {Subscription[]}
   * @private
   */
  private subscriptions: Subscription[] = [];

  /**
   * Constructor
   * @param {AbstractAuthService} authService
   * @param {ObjectsUtilityService} objectUtilityService
   * @param {ArrayUtilityService} arrayUtilityService
   * @param {TranslateService} translateService
   * @param {MainLayoutService} mainLayoutService
   * @param {QualipexHeaderService} qualipexHeaderService
   * @param {UtilityService} utilityService,
   * @param {Router} router
   */
  constructor(
    private authService: AbstractAuthService,
    private objectUtilityService: ObjectsUtilityService,
    private arrayUtilityService: ArrayUtilityService,
    private translateService: TranslateService,
    private mainLayoutService: MainLayoutService,
    private qualipexHeaderService: QualipexHeaderService,
    public utilityService: UtilityService,
    private router: Router
  ) {
    this.subscriptions.push(
      this.mainLayoutService
        .getSideBarExpandedStateObservable()
        .subscribe((value) => {
          this.isSideBarExpanded = value;
        })
    );

    this.subscriptions.push(
      this.qualipexHeaderService
        .getDisplaySearchObservable()
        .subscribe((value) => {
          this.displaySearchInput = value;
        })
    );

    this.subscriptions.push(
      this.mainLayoutService.getHeaderConfigObservable().subscribe((items) => {
        if (items != null) {
          if (
            items.menuItemsLanguage != null &&
            items?.menuItemsLanguage?.length > 0
          ) {
            this.menuItemsLanguage = this.arrayUtilityService.clone(
              items.menuItemsLanguage
            );
          }
          if (items.menuItemsUser != null && items?.menuItemsUser?.length > 0) {
            this.menuItemsUser = this.arrayUtilityService.clone(
              items.menuItemsUser
            );
          }
        }
      })
    );
  }

  /**
   * ngOnInit
   *
   * @returns {Promise<void>}
   */
  public async ngOnInit() {
    this.subscriptions.push(
      this.authService.getLoggedUserObservable().subscribe((user) => {
        this.user = this.objectUtilityService.clone(user);
        this.imagePathUser = this.user?.imageUrl;

        this.initMenuUser();
      })
    );

    await this.initMenuLanguage();
  }

  /**
   * Function responsible to reload menu data when user change language
   * @param {string} lang
   * @returns {Promise<void>}
   */
  public async changeLanguage(lang: string) {
    this.translateService.use(lang);
    await this.initMenuLanguage();
    await this.initMenuUser();
  }

  /**
   * ngOnDestroy
   *
   * Unsubscribe the user logged observable
   */
  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  /**
   * This method is responsible for calling the functions that will build the language menu
   * @returns {Promise<void>}
   */
  public async initMenuLanguage() {
    this.menuItemsLanguage = this.arrayUtilityService.clone(
      this.mainLayoutService.mapMenuItemsHelpers(this.menuItemsLanguage, this)
    );
    this.menuItemsLanguage = this.arrayUtilityService.clone(
      await this.mainLayoutService.getHeaderMenuItemsWithLabelsAsync(
        this.menuItemsLanguage
      )
    );
  }

  /**
   * This method is responsible for calling the functions that will build the user menu
   * @returns {Promise<void>}
   */
  public async initMenuUser() {
    this.menuItemsUser = this.arrayUtilityService.clone(
      this.mainLayoutService.mapMenuItemsHelpers(this.menuItemsUser, this)
    );
    this.menuItemsUser = this.arrayUtilityService.clone(
      await this.mainLayoutService.getHeaderMenuItemsWithLabelsAsync(
        this.menuItemsUser
      )
    );
  }

  public onPreferencesClicked() {
    this.router.navigateByUrl(
      '/' +
        environment.configurationItemsModuleConfigurations
          .configurationItemsRoutingBasePath +
        '/' +
        environment.configurationItemsModuleConfigurations
          .configurationItemsRoutingUserPath
    );
  }

  /**
   * This method is responsible for calling the function that will log out user
   * @returns {Promise<void>}
   */
  public onClickLogout = async () => {
    if (await this.authService.logoutAsync()) {
      //TODO Redirect after logout success. Route?
      return;
    }
  };

  public onClickProfile = async () => {
    this.qualipexHeaderService.setDisplayUserProfileValue(true);
  };

  public onSideBarToggle(): void {
    this.mainLayoutService.setSideBarExpandedState(!this.isSideBarExpanded);
  }

  public clearSearch(): void {
    this.searchString = null;
    this.qualipexHeaderService.setSearchValue(null);
  }

  public debounceSearch = this.utilityService.debounce(() => {
    this.qualipexHeaderService.setSearchValue(this.searchString);
  }, this.SEARCH_DEBOUNCE_MS);
}
