import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * Main Layout Service
 */
export class QualipexHeaderService {
  /**
   * displayUserProfileSubject
   * @type {BehaviorSubject<boolean>}
   * @private
   */
  private displayUserProfileSubject = new BehaviorSubject<boolean>(false);

  /**
   * searchValueSubject
   * @type {BehaviorSubject<string>}
   * @private
   */
  private searchValueSubject = new BehaviorSubject<string>(null);

  /**
   * Show/hide header subject
   * @type {BehaviorSubject<boolean>}
   * @private
   */
  private displaySearchSubject = new BehaviorSubject<boolean>(true);

  /**
   * constructor
   */
  constructor() {}

  /**
   * Setter for displayUserProfileSubject subject
   * @param value
   */
  public setDisplayUserProfileValue(value: boolean): void {
    this.displayUserProfileSubject.next(value);
  }

  /**
   * Getter for displayUserProfileSubject subject
   *
   * @returns The displayUserProfileSubject subject as an observable
   */
  public getDisplayUserProfileObservable(): Observable<boolean> {
    return this.displayUserProfileSubject.asObservable();
  }

  /**
   * Getter for displayUserProfileSubject subject
   *
   * @returns The displayUserProfileSubject subject current value
   */
  public getDisplayUserProfileValue(): boolean {
    return this.displayUserProfileSubject.getValue();
  }

  /**
   * Setter for searchValueSubject subject
   * @param value
   */
  public setSearchValue(value: string): void {
    this.searchValueSubject.next(value);
  }

  /**
   * Getter for searchValueSubject subject
   *
   * @returns The searchValueSubject subject as an observable
   */
  public getSearchValueObservable(): Observable<string> {
    return this.searchValueSubject.asObservable();
  }

  /**
   * Getter for searchValueSubject subject
   *
   * @returns The searchValueSubject subject current value
   */
  public getSearchValueValue(): string {
    return this.searchValueSubject.getValue();
  }

  /**
   * Setter for displaySearchSubject subject
   * @param value
   */
  public setDisplaySearchValue(value: boolean): void {
    this.displaySearchSubject.next(value);
  }

  /**
   * Getter for displaySearchSubject subject
   *
   * @returns The displaySearchSubject subject as an observable
   */
  public getDisplaySearchObservable(): Observable<boolean> {
    return this.displaySearchSubject.asObservable();
  }

  /**
   * Getter for displaySearchSubject subject
   *
   * @returns The displaySearchSubject subject current value
   */
  public getDisplaySearchValue(): boolean {
    return this.displaySearchSubject.getValue();
  }
}
