import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileFormPage } from './components/user-profile-form/user-profile-form.page';
import { UsersPage } from './users.page';
import { QualipexLayoutsHeaderSidebarComponent } from '../layouts/pages/qualipex-layouts-header-sidebar';

const routes: Routes = [
  {
    path: '',
    component: QualipexLayoutsHeaderSidebarComponent,
    children: [
      { path: '', component: UsersPage },
      { path: 'my-account/profile', component: UserProfileFormPage },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
